const firebaseConfig = {
  apiKey: "AIzaSyAoKJXpUde_th9wpB58nXgGTNXKCA-jeiA",
  authDomain: "privacy-police-generator-new.firebaseapp.com",
  projectId: "privacy-police-generator-new",
  storageBucket: "privacy-police-generator-new.appspot.com",
  messagingSenderId: "377337092162",
  appId: "1:377337092162:web:2f272dbd66aab23359f5db"
};


export default firebaseConfig